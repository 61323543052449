import React from 'react';
import { Game } from '../../../types/game';
import { Player } from '../../../types/player';
import { CardPicker } from '../../Players/CardPicker/CardPicker';
import { Players } from '../../Players/Players';
import { GameController } from '../GameController/GameController';
import './GameArea.css';
import { Container, Divider, Typography } from '@material-ui/core';

interface GameAreaProps {
  game: Game;
  players: Player[];
  currentPlayerId: string;
}
export const GameArea: React.FC<GameAreaProps> = ({ game, players, currentPlayerId }) => {
  return (
    <>
      <div className='ContentArea'>
      <GameController game={game} currentPlayerId={currentPlayerId} />
      
      <Divider />
      <Typography variant='h6' align='center'>
        Team Estimates
        </Typography>
        <Players game={game} players={players} currentPlayerId={currentPlayerId} />
      </div>
      <Divider/>
      <Typography variant='h6' align='center' >
        Your Estimate
        </Typography>
      <div className='Footer'>
      <Container maxWidth="lg">
        <CardPicker game={game} players={players} currentPlayerId={currentPlayerId} />
        </Container>
      </div>
    </>
  );
};

export default GameArea;
