import React, { useEffect } from 'react';

declare global {
  interface Window {
    adsbygoogle: any;
  }
}
export const GoogleAd = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);
 
  return (
    <>
      <ins className="adsbygoogle"
     style={{ display: 'inline-block', width: '728px', height: '90px', margin: '30px' }}
     data-ad-client="ca-pub-2623249895336741"
     data-ad-slot="6105573532"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    </>
  );
};
