import { Divider, Slide, Typography } from '@material-ui/core';
import CopyrightIcon from '@material-ui/icons/Copyright';
import './Footer.css';

export const Footer = () => {
  return (
    <footer>
      <Slide in={true} direction='up' timeout={3000}>
        <div className='FooterSection'>
          <Divider variant='middle'></Divider>
          <div className='FooterContainer'>
            <div className='FooterItemContainer'>
              <CopyrightIcon color='secondary' fontSize='small' />
              <Typography color='textSecondary' variant='body2'>
                {new Date().getFullYear()} Scrum Poker Team
              </Typography>
            </div>

            <Divider orientation='vertical' flexItem></Divider>
            <div className='FooterItemContainer'>
              <Typography color='textSecondary' variant='body2'>
                Feedback: feedback@scrumpoker.team
              </Typography>
            </div>

            <Divider orientation='vertical' flexItem></Divider>
          
            <Divider orientation='vertical' flexItem></Divider>
            <div className='FooterItemContainer'>
            <a href="https://twitter.com/nkvDev?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-related="nkvDev" data-show-count="false">Follow @nkvDev</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> </div>
          </div>
        </div>
      </Slide>
    </footer>
  );
};
